import { Component } from "react";

// Components
import AsyncRitualButton from "../global/AsyncRitualButton";
import DiscountButtonCopy from "./DiscountButtonCopy";

export default class DiscountButton extends Component {
  render() {
    const {
      className,
      promotion,
      prefix,
      basePrice,
      price,
      showDash = true,
      allowCurrencyPostfix,
      showPricing = true,
      discountPrice,
      floatText,
    } = this.props;

    return (
      <AsyncRitualButton {...this.props} className={className}>
        <DiscountButtonCopy
          prefix={prefix}
          basePrice={basePrice}
          price={price}
          promotion={promotion}
          showDash={showDash}
          allowCurrencyPostfix={allowCurrencyPostfix}
          showPricing={showPricing}
          discountPrice={discountPrice}
          floatText={floatText}
        />
      </AsyncRitualButton>
    );
  }
}
